<template>
  <span>
    <div class="v-data-footer">
      <div class="v-data-footer__select">
        Rows per page:
        <v-select
          :items="perPageOptions"
          :value="currentPerPage"
          class="v-data-footer__select d-inline-block"
          dense
          hide-details
          @change="$emit('change-per-page', $event)"
        />
      </div>

      <div class="v-data-footer__icons-before">
        <v-btn :disabled="isFirstPage" icon text rounded @click="$emit('prev-page')">
          <v-icon>keyboard_arrow_left</v-icon>
        </v-btn>
      </div>
      <div class="v-data-footer__icons-after">
        <v-btn :disabled="isLastPage" icon text rounded @click="$emit('next-page')">
          <v-icon>keyboard_arrow_right</v-icon>
        </v-btn>
      </div>
    </div>
  </span>
</template>

<script>
import { PER_PAGE_OPTIONS, DEFAULT_PER_PAGE } from '@/constants'

export default {
  props: {
    length: {
      type: Number,
      default: 0,
    },
    isFirstPage: {
      type: Boolean,
      default: true,
    },
    isLastPage: {
      type: Boolean,
      default: true,
    },
    currentPerPage: {
      type: Number,
      default: DEFAULT_PER_PAGE,
    },
  },
  data: () => ({
    perPageOptions: PER_PAGE_OPTIONS,
  }),

  watch: {},
}
</script>
