<template>
  <v-container>
    <base-card>
      <div slot="heading" class="title font-weight-light">Client Management</div>
      <v-layout row wrap>
        <v-flex xs12>
          <v-row justify="space-between">
            <v-col cols="8" align-self="center" />
            <v-col cols="4">
              <v-text-field
                v-model="searchName"
                append-icon="search"
                :label="$t('clients.name')"
                single-line
                hide-details
                @keydown.enter="searchByName"
              />
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="clients"
            :items-per-page="perPage"
            class="elevation-1"
            :hide-default-footer="true"
          >
            <template v-slot:item="props">
              <tr>
                <td>
                  <div style="max-width: 200px;" class="text-truncate">
                    {{ props.item.name }}
                  </div>
                </td>
                <td>
                  <div style="max-width: 200px;" class="text-truncate">
                    {{ props.item.ip_restriction_cidr }}
                  </div>
                </td>
                <td class="layout">
                  <v-icon small class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
          <pagination
            :current-per-page="perPage"
            :is-first-page="isFirstPage"
            :is-last-page="isLastPage"
            @prev-page="prevPage"
            @next-page="nextPage"
            @change-per-page="searchByPerPage"
          />
        </v-flex>
      </v-layout>
    </base-card>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="teal">
          <span class="headline white--text">Edit Client</span>
        </v-card-title>
        <v-form ref="form">
          <v-card-text>
            <client-form :client="currentClient" />
          </v-card-text>
          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="hideDialog"> Close </v-btn>
            <v-btn color="blue darken-1" text @click="onUpdate"> Save </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Pagination from '@/components/ui/Pagination.vue'
import { DEFAULT_PER_PAGE } from '@/constants'
import ClientForm from './ClientForm.vue'
import BaseCard from '../components/common/BaseCard.vue'

export default {
  name: 'Clients',
  components: {
    Pagination,
    ClientForm,
    BaseCard,
  },
  data() {
    return {
      dialog: false,
      currentClient: null,
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'IP Restriction',
          align: 'left',
          sortable: false,
          value: 'ip_restriction_cidr',
        },
        {
          text: 'Actions',
          align: 'left',
          sortable: false,
          value: 'name',
        },
      ],
      perPage: DEFAULT_PER_PAGE,
      searchName: '',
    }
  },
  mounted() {
    this.search(0)
  },

  computed: {
    ...mapState({
      clients: (state) => state.client.clients,
      count: (state) => state.client.count,
      offset: (state) => state.client.offset,
    }),
    isFirstPage() {
      return this.offset === 0
    },
    isLastPage() {
      return this.offset + this.perPage > this.count
    },
  },

  methods: {
    ...mapActions(['fetchClients', 'updateClient']),

    hideDialog() {
      this.dialog = false
      this.currentClient = null
    },
    async onUpdate() {
      if (!this.$refs.form.validate()) {
        return
      }
      const ipRestriction = this.currentClient.ip_restriction_cidr
      const params = {
        id: this.currentClient.id,
        ip_restriction_cidr: ipRestriction ? ipRestriction.split(',') : null,
      }
      await this.updateClient(params)
      this.hideDialog()
      this.$snotify.success('Updated successfully!')
    },
    editItem(item) {
      this.currentClient = {
        ...item,
        ip_restriction_cidr: (item.ip_restriction_cidr || []).join(','),
      }
      this.dialog = true
    },
    searchByPerPage(perPage) {
      this.perPage = perPage
      this.search(0)
    },
    searchByName() {
      this.search(0)
    },
    prevPage() {
      let newOffset = this.offset - this.perPage
      if (newOffset < 0) {
        newOffset = 0
      }
      this.search(newOffset)
    },
    nextPage() {
      const newOffset = this.offset + this.perPage
      this.search(newOffset)
    },
    search(offset) {
      const params = {
        perPage: this.perPage,
        name: this.searchName,
        offset,
      }
      this.fetchClients(params)
    },
  },
}
</script>
