<template>
  <v-container v-if="client" grid-list-md>
    <v-layout wrap>
      <v-flex xs12>
        <span>{{ client.name }}</span>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model="client.ip_restriction_cidr"
          :rules="ipRestrictionRules"
          label="IP Restriction"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import isCidr from 'is-cidr'

export default {
  props: {
    client: {
      type: Object,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      ipRestrictionRules: [
        (v) => {
          if (!v) {
            return true
          }
          const cidrs = v.split(',')
          if (cidrs.every((cidr) => isCidr.v4(cidr))) {
            return true
          }
          return `Invalid cidr syntx: ${v}`
        },
      ],
    }
  },
}
</script>
